const INFO = {
	main: {
		title: "Sean Tsang - ANALYST PROGRAMMER",
		name: "Sean Tsang",
		email: "seantsangmk@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/seantsangmk",
		linkedin: "https://www.linkedin.com/in/sean-tsang/",
		website: "https://www.findagoodboss.com/post/%E7%A8%8B%E5%BC%8F%E7%B7%A8%E7%A2%BC%E8%A3%8F%E7%9A%84%E8%AC%99%E5%8D%91-%E5%9B%9B%E5%80%8B%E6%9C%88bootcamp%E7%9A%84%E4%BA%BA%E7%94%9F%E5%95%9F%E7%A8%8B",
		stackoverflow: "https://stackoverflow.com/users/17681630/seantsang",
	},

	homepage: {
		title: "Achieving Excellence in Full-Stack Development and AWS Cloud Services",
		description: [
			"Experienced Software Engineer specializing in frontend development with a focus on TypeScript, ReactJS, and Next.js.",
			"As an AWS Certified Solutions Architect Associate, I bring extensive knowledge of cloud services and architecture to every project.",
			"My expertise extends to developing Java Lambda applications for efficient batch processing and implementing GitLab CI/CD pipelines for seamless automated deployments."
		]
	},

	about: {
		title: "I'm Sean. I have a passion for basketball, sneakers, playing cello, and exploring cars.",
		description:
			"In addition to my professional work as a programmer, I have a diverse range of interests including playing basketball and cello, listening different kinds of music, spending quality time with loved ones, and providing support to others. I greatly enjoy engaging in meaningful communication with people.",
	},

	achievements: {
		title: "I am dedicated to continuous learning and personal growth.",
		description:
			"I prioritize continuous learning and personal development, consistently striving for growth even in the face of challenges and demanding circumstances.",
	},

	projects: [
		{
			title: "WebApp SSO with Typescript, NextJS, API Integration and Security Enhancements",
			description: "Revamped project from scratch using Typescript with NextJS (ReactJS) for a modern web app. Seamlessly integrated APIs, implemented efficient Captcha handling, i18n, and OAuth for enhanced security.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png", "https://cdn.worldvectorlogo.com/logos/react-2.svg", "https://cdn.worldvectorlogo.com/logos/docker.svg","https://cdn.worldvectorlogo.com/logos/aws-2.svg"],
			linkText: "HKTDC",
			link: "https://accounts2.hktdc.com/",
		},
		{
			title: "Automation Test Cases with Katalon, Cypress, and Jest with Gitlab CICD",
			description: "Created an extensive set of automation test cases with Katalon, Cypress, and Jest, covering unit testing, integration testing, and end-to-end testing. Utilized gitlab pipeline for test execution.",
			logo: ["https://upload.wikimedia.org/wikipedia/commons/0/00/Katalon-logo-png.png","https://www.cypress.io/images/layouts/cypress-logo.svg","https://cdn.worldvectorlogo.com/logos/gitlab.svg"],
		},
		{
			title: "Java Lambda Function for Dynamodb Event",
			description:
				"Created a Java-based serverless lambda function to effectively manage event handling and querying/inserting items in Dynamodb.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png", "https://cdn.worldvectorlogo.com/logos/aws-2.svg", "https://cdn.worldvectorlogo.com/logos/amazon-database.svg"],
		},

		{
			title: "Python Lambda Function for AWS X-Ray Tracing and Logging to Elasticsearch",
			description:
				"Developed a Python-based serverless lambda function for capturing request information using AWS X-Ray for tracing purposes and logging events to Elasticsearch.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png", "https://cdn.worldvectorlogo.com/logos/aws-2.svg", "https://cdn.worldvectorlogo.com/logos/elasticsearch.svg"],
		},
		{
			title: "Jira, Git, and Confluence for Transparent and Productive Project Execution",
			description:
				"Utilized Scrum, Jira, Git, and Confluence for efficient project management, version control, collaboration, and documentation to ensure transparency and productivity across the organization.",
			logo: [
				"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtoXpzkjeaEr1vPZf-5jy3pyMus27UohQ_nQ&usqp=CAU",
				"https://mhutchie.gallerycdn.vsassets.io/extensions/mhutchie/git-graph/1.30.0/1617594001998/Microsoft.VisualStudio.Services.Icons.Default",
				"https://cdn.iconscout.com/icon/free/png-256/free-confluence-3628704-3029929.png?f=webp"
			]
		},
		{
			title: "Youthful Website Page using HTML and CSS, MySQL for Data Handling",
			description:
				"Designed and developed a visually appealing website page with a youthful style using HTML and CSS. Implemented MySQL with PHP Laravel to securely store data from online submit forms.",
			logo: [
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
				"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
				"https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png",
			],
			linkText: "HKT-EYE",
			link: "https://www.hkt-eye.com/eyeAIrobot/",
		}
	],
};

export default INFO;
