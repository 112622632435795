import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./styles/achievement.css";

const Achievement = props => {
	const { title, description, date, link, linkText } = props;

	return (
		<div className="homepage-achievement">
			<div className="homepage-achievement-content">
				<div className="homepage-achievement-date">
					|&nbsp;&nbsp;&nbsp;{date}
				</div>
				<div className="homepage-achievement-title">{title}</div>
				<div className="homepage-achievement-description">
					{description}
				</div>
				<div className="homepage-achievement-link">
					<Link to={link}>
						{linkText}
						<FontAwesomeIcon
							style={{ fontSize: "10px" }}
							icon={faChevronRight}
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Achievement;
