import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./hktdc.png"
								alt="hktdc"
								className="work-image"
							/>
							<div className="work-title">
								HKTDC 香港貿易發展局
							</div>
							<div className="work-subtitle">
								Analyst Programmer
							</div>
							<div className="work-duration">2022 June - Present</div>
						</div>

						<div className="work">
							<img
								src="./hkt.png"
								alt="hkt"
								className="work-image"
							/>
							<div className="work-title">
								HKT 香港電訊
							</div>
							<div className="work-subtitle">Web Developer</div>
							<div className="work-duration">2021 July - 2022 June</div>
						</div>

						<div className="work">
							<img
								src="./stockX.jpg"
								alt="hkt"
								className="work-image"
							/>
							<div className="work-title">StockX / GOAT</div>
							<div className="work-subtitle">
								Sneaker Reseller
							</div>
							<div className="work-duration">2016 - 2021</div>
						</div>

						<div className="work">
							<img
								src="./hkbea.jpg"
								alt="hkt"
								className="work-image"
							/>
							<div className="work-title">
								HKBEA 東亞銀行
							</div>
							<div className="work-subtitle">
								CS Representative
							</div>
							<div className="work-duration">2014 - 2015</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
