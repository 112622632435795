import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = props => {
  const { logo, title, description, linkText, link } = props;

  return (
    <div className="project">
      <Link to={link}>
        <div className="project-container">
          <div className="project-logo">
            {logo.map((url, index) => (
              <img key={index} src={url} alt="logo" style={{padding:"1em"}}/>
            ))}
          </div>
          <div className="project-title">
            {title}
          </div>
          <div
            className="project-description"
          >
            {description}
          </div>
          {link && (
            <div className="project-link">
              <div className="project-link-icon">
                <FontAwesomeIcon icon={faLink} />
              </div>
              <div className="project-link-text">{linkText}</div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default Project;