import React from "react";

function achievement_0() {
	return {
		date: "30 Jun 2024",
		title: "HKTDC Dragon Boat Team’s Victory in the Dragon Boat Short Course Races",
		description:
			"I am thrilled to be a part of the HKTDC dragon boat team and excited to share that we emerged as the Champion of the Mixed Boat Plate Final 🐲 ! This achievement truly highlights the power of teamwork, as our team displayed remarkable collaboration, skill, and dedication throughout the competition. It has been an incredible experience. 🔥",
		keywords: [
			"HKTDC dragon boat team",
			"Champions",
			"Teamwork",
		],
		style: `
				.achievement-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="achievement-content">
					<div className="paragraph">Content of achievement 0</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
		link: "https://www.linkedin.com/feed/update/urn:li:activity:7213806220531388416/",
		linkText: "Checkout my LinkedIn post ",
	};
}

function achievement_1() {
	return {
		date: "17 Mar 2024",
		title: "AWS Certified Solutions Architect – Associate exam passed",
		description:
			"I’m happy to share that I’ve obtained a new certification: AWS Certified Solutions Architect – Associate from Amazon Web Services Training and Certification!",
		keywords: [
			"Cloud Computing",
			"AWS Certified Solutions Architect – Associate",
			"Amazon",
		],
		style: `
				.achievement-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="achievement-content">
					<div className="paragraph">Content of achievement 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
		link: "https://www.credly.com/badges/74b525e2-424d-46ef-984f-e4c24ee09ab7",
		linkText: "Show credential ",
	};
}

function achievement_2() {
	return {
		date: "10 Mar 2024",
		title: "Guest performer for my alma mater's 25th anniversary celebration",
		description:
			"I am truly honored to have been invited as a guest performer for my alma mater's 25th anniversary celebration. It's hard to believe how quickly time has passed, and I am filled with immense joy to have the opportunity to perform alongside the talented students I once taught.",
		keywords: [
			"Cello",
			"Performer",
		],
		style: `
				.achievement-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="achievement-content">
					<div className="paragraph">Content of achievement 2</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
		link: "https://www.linkedin.com/feed/update/urn:li:activity:7172795874048196608/",
		linkText: "Checkout my LinkedIn post ",
	};
}

function achievement_3() {
	return {
		date: "10 Sep 2023",
		title: "Incredible experience as an OC member of the \"www.freedom Web-Accessibility\" event hosted by JCI Dragon",
		description:
			"This experience served as a reminder of the importance of promoting the popularization and application of the internet, and how it can bring greater convenience to citizens' lives. It was an honor to promote related work on web accessibility and cyber security, allowing more people to enjoy the convenience and safety brought by the internet.",
		keywords: [
			"Web-Accessibility",
			"Freedom",
		],
		style: `
				.achievement-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="achievement-content">
					<div className="paragraph">Content of achievement 3</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
		link: "https://www.linkedin.com/feed/update/urn:li:activity:7056167757561659392/",
		linkText: "Checkout my LinkedIn post ",
	};
}

const myachievements = [achievement_0, achievement_1, achievement_2, achievement_3];

export default myachievements;
